import { PageAlwaysVisible, PageForty, device } from "monica-alexandria";
import styled from "styled-components";

export const Legal = styled(PageForty)`
  padding-left: 0;
  padding-right: 0;
  align-items: flex-start;

  h1 {
    margin-bottom: 4rem;
  }

  h2 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid var(--main);
    color: var(--main);
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 1rem;
    color: var(--greyDark);
    border-left: 1rem solid var(--focus);
    padding-left: 1rem;
  }

  ul {
    list-style: circle;
    margin-left: 2rem;
    li {
      font-size: 1.5rem;
      font-family: "fontLight";
      margin-left: 2rem;
      list-style: circle;
      margin-top: 1rem;
    }
  }

  ul:first-child{
    margin-left: 0;
  }

  p {
    margin-top: 0.5rem;
  }

  ol {
    li {
      font-size: 1.5rem;
      font-family: "fontLight";
      margin-left: 2rem;
      list-style: decimal;
      margin-top: 1rem;
    }
  }

  a {
    color: var(--main);

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LegalTitle = styled.div`
  padding: 2rem 0;

  h1 {
    color: var(--focus);
    margin-bottom: 0;
  }
`;

export const LegalLogo = styled.img`
  width: 15rem;
  margin-bottom: 1rem;
`;

export const LegalLeft = styled(PageAlwaysVisible)`
  background: var(--dark);
  padding-left: 4rem;
  padding-right: 4rem;
  min-height: 100vh;
  position: sticky;
  top: 0;

  @media ${device.md} {
    position: static;
  }

  @media ${device.sm} {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  li {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
`;
export const LegalDate = styled.div`
  padding-top: 2rem;

  h6 {
    font-style: italic;
    text-align: right;
  }
`;

export const LegalRight = styled(PageAlwaysVisible)`
padding-right: 4rem;

  @media ${device.md} {
    padding-left: 4rem;
  }

  @media ${device.sm} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
