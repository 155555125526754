import {
  PageAlwaysVisible,
} from "monica-alexandria";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Legal,
  LegalDate,
  LegalLeft,
  LegalLogo,
  LegalRight,
  LegalTitle,
} from "./Legal.css";
import { useLocation } from "react-router-dom";

export default function GDPR() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);
  return (
    <Legal>
      <LegalLeft>
        <LegalTitle>
          <LegalLogo src="https://genius1071.friktoriaservers.net/doitforme/logo/line_logo_dark.svg" />
          <h1>DATA PROTECTION</h1>
        </LegalTitle>
        <div id="table-of-contents">
          <ul>
            <li>
              <a href="#what-information-we-collect">
                1. WHAT INFORMATION DO WE COLLECT?
              </a>
            </li>
            <li>
              <a href="#how-we-process-your-information">
                2. HOW DO WE PROCESS YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a href="#information-sharing">
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li>
              <a href="#cookies-and-tracking">
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li>
              <a href="#social-logins">
                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
            </li>
            <li>
              <a href="#international-transfers">
                6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
              </a>
            </li>
            <li>
              <a href="#data-retention">
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a href="#information-from-minors">
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </a>
            </li>
            <li>
              <a href="#privacy-rights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li>
              <a href="#do-not-track">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>
            <li>
              <a href="#policy-updates">
                11. DO WE MAKE UPDATES TO THIS NOTICE?
              </a>
            </li>
            <li>
              <a href="#contact-us">
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
            </li>
            <li>
              <a href="#review-update-delete-data">
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </a>
            </li>
            <li>
              <a href="#cookie-policy">14. COOKIE POLICY</a>
            </li>
            <li>
              <a href="#google-limited-use-requirments">15. GOOGLE'S LIMITED USE REQUIREMENTS</a>
            </li>
          </ul>
        </div>
      </LegalLeft>
      <LegalRight>
        <LegalDate>
          <h6>Last updated: March 20th, 2025</h6>
        </LegalDate>
        <h1>PRIVACY AND POLICY</h1>
        <div id="privacy-notice">
          <h2>Privacy Notice for Services</h2>
          <p>
            This privacy notice for ("Services"), such as when you ("we," "us,"
            or "our"), describes how and why we might collect, store, use,
            and/or share ("process") your information when you use our services.
            Questions or concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services.
          </p>

          <h3>Summary of Key Points</h3>
          <p>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </p>

          <ul>
            <li>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use.
            </li>

            <li>
              <strong>Do we process any sensitive personal information?</strong>
              We do not process sensitive personal information.
            </li>

            <li>
              <strong>Do we receive any information from third parties?</strong>
              We may receive information from public databases, marketing
              partners, social media platforms, and other outside sources.
            </li>
            <li>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so.
            </li>

            <li>
              <strong>
                In what situations and with which parties do we share personal
                information?
              </strong>
              We may share information in specific situations and with specific
              third parties.
            </li>

            <li>
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information.
            </li>

            <li>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by submitting a data subject access
              request, or by contacting us. We will consider and act upon any
              request in accordance with applicable data protection laws.
            </li>
          </ul>

          <p>
            Want to learn more about what we do with any information we collect?
          </p>
        </div>
        <div id="what-information-we-collect">
          <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
          <h3>Personal Information You Disclose to Us</h3>
          <p>
            <strong>In Short:</strong> We collect personal information that you
            provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p>
            <strong>Sensitive Information.</strong> We do not process sensitive
            information.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <h3>Information Automatically Collected</h3>
          <p>
            <strong>In Short:</strong> Some information - such as your Internet
            Protocol (IP) address and/or browser and device characteristics—is
            collected automatically when you visit our Services.
          </p>
          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
        </div>
        <div id="how-we-process-your-information">
          <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
          <p>
            <strong>In Short:</strong> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent.
          </p>
          <p>
            <h3>Legal Basis for Processing Data</h3>
            <p>In accordance with Article 6 of the GDPR, we only process personal data when there is a lawful basis for doing so. These bases include:</p>       
            <ul>
              <li>User Consent – When you explicitly provide consent (e.g., signing up, connecting social accounts, subscribing to marketing emails).</li>
              <li>Performance of a Contract – When processing is necessary to fulfill our contract with you, such as providing services through our platforms.</li>
              <li>Legitimate Interests – When processing is needed to enhance security, prevent fraud, analyze trends, or improve our services, provided that such interests do not override user rights.</li>
              <li>Legal Obligations – When required to comply with a legal duty, such as tax regulations or responding to law enforcement.</li>
            </ul>
          </p>
        </div>

        <div id="information-sharing">
          <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
          <p>
            <strong>In Short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties.
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <strong>Affiliates.</strong> We may share your information with
              our affiliates, in which case we will require those affiliates to
              honor this privacy notice. Affiliates include our parent company
              and any subsidiaries, joint venture partners, or other companies
              that we control or that are under common control with us.
            </li>
            <li>
              <strong>Business Partners.</strong> We may share your information
              with our business partners to offer you certain products,
              services, or promotions.
            </li>
          </ul>
          <h3>Third-Party Data Processors</h3>
          <p>To provide our services, we work with third-party service providers who process personal data on our behalf. These include:</p>
          <ul>
            <li>Stripe (Payments Processing)</li>
            <li>Meta (Facebook Business & Advertising API)</li>
            <li>Google (Google Ads API, Analytics, Google workspace)</li>
            <li>Hotjar (User Behavior Analytics)</li>
            <li>FRIKTORIA</li>
            <li>Mailchimp</li>
          </ul>
          <p>All third-party providers comply with GDPR and process data strictly for service delivery.</p>
        </div>
        <div id="cookies-and-tracking">
          <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
          <p>
            <strong>In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information.
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our
            <a href="#cookie-notice">Cookie Notice</a>.
          </p>
        </div>
        <div id="social-logins">
          <h2>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
          <p>
            <strong>In Short:</strong> If you choose to register or log in to
            our Services using a social media account, we may have access to
            certain information about you.
          </p>
          <p>
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook). We
            collect specific information from your social media account when you
            connect it to our Services.
          </p>
          <p>
            When you connect your Facebook account to our Services, we access
            certain information from your Facebook profile and associated pages
            and business manager accounts. This includes, but is not limited to,
            your name, email address, friends list, profile picture, page data,
            and other publicly shared or agreed-to-share information. We
            specifically collect data related to your pages, business manager
            accounts, and their assets to assist you in creating and managing
            advertisements. Additionally, for the creation of advertisement
            reports, we collect comprehensive data from your Ads Manager
            Account, including all campaign details on your Facebook pages.
          </p>
          <p>
            This data collection is vital to provide personalized features,
            including social media integrations and tailored ad creation and
            management services. We commit to using this information strictly
            within the scope of enhancing your experience with our Services and
            in compliance with Facebook's policies and applicable privacy laws.
            Please be aware that this collection is subject to Facebook's data
            handling practices, and we encourage you to review Facebook's
            privacy policies for more details on their use of your information.
          </p>
          <p>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>
          <h3>Privacy Policy for Facebook Lead Ads</h3>
          <ul>
            <li>
              <strong>Information We Collect: </strong>When you submit a form through our Facebook Lead Ads, we collect personal information including, but not limited to, your name, email address, phone number, and any other details you provide.
            </li>
            <li>
              <strong>How We Use Your Information: </strong>The information you provide will be used for the following purposes:
              <ul>
                <li>To send you newsletters and updates about our products, services, and promotions.</li>
                <li>To communicate with you regarding your inquiries or interests.</li>
              </ul>
            </li>
            <li>
              <strong>Use of Questad.doitforme.eu APIs: </strong>Our Facebook Lead Ads may be created and managed using Questad.doitforme.eu APIs. By submitting your information, you acknowledge that these APIs facilitate the creation and management of ads and that your data may be processed through these systems to ensure proper handling and management of your submission.
            </li>
            <li>
              <strong>Sharing Your Information with Third Parties: </strong>To assist us in managing and processing the leads generated through our Facebook Lead Ads, we may share your information with our third-party service provider, DO IT FOR ME PC. This company assists us with lead management and may have access to the personal information you submit. DO IT FOR ME PC is committed to maintaining the confidentiality and security of your data and will use your information solely for the purposes of assisting us.
            </li>
            <li>
              <strong>Data Security: </strong> We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of electronic transmission or storage is 100% secure.
            </li>
            <li>
              <strong>Your Rights: </strong>You have the right to access, correct, or delete your personal information. You may also opt out of receiving newsletters or marketing communications from us at any time by following the unsubscribe instructions provided in our communications or by contacting us directly.
            </li>
            <li>
              <strong>Changes to This Privacy Policy: </strong>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
            </li>
            <li>
              <strong>Contact Us: </strong>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@doitforme.eu.
            </li>
            <li>Users who collect lead data from ads or form submissions are solely responsible for handling customer information in compliance with GDPR and other data protection laws.</li>
          </ul>
          <p>
            By submitting your information through our Facebook Lead Ads, you consent to the collection, use, and sharing of your personal data as described in this Privacy Policy, including processing through Questad.doitforme.eu APIs.
          </p>
        </div>
        <div id="international-transfers">
          <h2>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
          <p>
            <strong>In Short:</strong> We may transfer, store, and process your
            information in countries other than your own.
          </p>
          <p>
            Our servers are located in Greece. If you are accessing our
            Services from outside Greece, please be aware that your
            information may be transferred to, stored, and processed by us in
            our facilities and by those third parties with whom we may share
            your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR
            PERSONAL INFORMATION?" above), in Greece and other countries.
          </p>
          <p>
            If you are a resident in the European Economic Area (EEA), United
            Kingdom (UK), or Switzerland, then these countries may not
            necessarily have data protection laws or other similar laws as
            comprehensive as those in your country. However, we will take all
            necessary measures to protect your personal information in
            accordance with this privacy notice and applicable law.
          </p>
        </div>

        <div id="data-retention">
          <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
          <p>
            <strong>In Short:</strong> We keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy notice
            unless otherwise required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <p>Retention Periods:</p>
          <ul>
            <li>Account Information & Profiles: Stored until user deletion request (7 days after the request)</li>
            <li>Lead Data: Retained for 12 months</li>
            <li>Leads generated through our platform are stored for a maximum of 24 months, after which they are automatically deleted unless users export or request retention.</li>
          </ul>
          <p>Users can request immediate deletion of data at any time via account settings or by contacting support at support@doitforme.eu.</p>
        </div>

        <div id="information-from-minors">
          <h2>8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
          <p>
            <strong>In Short:</strong> We do not knowingly collect data from or
            market to children under 18 years of age.
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent's use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at support@doitforme.eu.
          </p>
        </div>
        <div id="privacy-rights">
          <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
          <p>
            <strong>In Short:</strong> You may review, change, or terminate your
            account at any time.
          </p>
          <p>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            at any time by contacting us by using the contact details provided
            in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <p>
            <strong>Account Information</strong>
            <br />
            If you would at any time like to review or change the information in
            your account or terminate your account, you can send us a request at support@doitforme.eu.
          </p>
          <p>
            <strong>Account Information</strong>
            <br />
            If you would at any time like to delete your account, you may request it directly from platform's user settings, then privacy policy and finally click the "account delete" button.
          </p>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases within seven (7) days.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
          <h3>Rights to Data Portability</h3>
          <p>You have the right to request a structured, commonly used, and machine-readable copy of your personal data.</p>
          <ul>
            <li>How to request: Submit a Data Portability Request via email to support@doitforme.eu.</li>
            <li>Scope: We will provide a copy of your profile and any user-uploaded content.</li>
          </ul>
        </div>

        <div id="do-not-track">
          <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>

        <div id="notice-updates">
          <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
          <p>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>

        <div id="contact-us">
          <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
          <p>
            If you have questions or comments about this notice, you may contact
            us by post at: Mikrasiaton 75, 38333, Volos, Greece
          </p>
        </div>
        <div id="review-update-delete-data">
          <h2>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h2>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a
            data subject access request.
          </p>
        </div>

        <div id="cookie-policy">
          <h2>14. COOKIE POLICY</h2>
          <p>
            This Cookie Policy explains how DO IT FOR ME PC ("Company," "we,"
            "us," and "our") uses cookies and similar technologies to recognize
            you when you visit our website at
            <a href="https://doitforme.eu">https://doitforme.eu</a> ("Website").
            It explains what these technologies are and why we use them, as well
            as your rights to control our use of them.
          </p>
          <p>
            In some cases we may use cookies to collect personal information, or
            that becomes personal information if we combine it with other
            information.
          </p>
          <h3>What are cookies?</h3>
          <p>
            Cookies are small data files that are placed on your computer or
            mobile device when you visit a website. Cookies are widely used by
            website owners in order to make their websites work, or to work more
            efficiently, as well as to provide reporting information.
          </p>
          <p>
            Cookies set by the website owner (in this case, DO IT FOR ME P.C)
            are called "first-party cookies." Cookies set by parties other than
            the website owner are called "third-party cookies." Third-party
            cookies enable third-party features or functionality to be provided
            on or through the website (e.g., advertising, interactive content,
            and analytics). The parties that set these third-party cookies can
            recognize your computer both when it visits the website in question
            and also when it visits certain other websites.
          </p>
          <h3>Why do we use cookies?</h3>
          <p>
            We use first- and third-party cookies for several reasons. Some
            cookies are required for technical reasons in order for our Website
            to operate, and we refer to these as "essential" or "strictly
            necessary" cookies. Other cookies also enable us to track and target
            the interests of our users to enhance the experience on our Online
            Properties. Third parties serve cookies through our Website for
            advertising, analytics, and other purposes. This is described in
            more detail below.
          </p>
          <h3>How Can I Control Cookies?</h3>
          <p>
            You have the right to decide whether to accept or reject cookies.
            You can exercise your cookie rights by setting your preferences in
            the Cookie Consent Manager. The Cookie Consent Manager allows you to
            select which categories of cookies you accept or reject. Essential
            cookies cannot be rejected as they are strictly necessary to provide
            you with services.
          </p>
          <p>
            The Cookie Consent Manager can be found in the notification banner
            and on our website. If you choose to reject cookies, you may still
            use our website though your access to some functionality and areas
            of our website may be restricted. You may also set or amend your web
            browser controls to accept or refuse cookies.
          </p>
          <p>
            The specific types of first- and third-party cookies served through
            our Website and the purposes they perform are described in the table
            below (please note that the specific cookies served may vary
            depending on the specific Online Properties you visit):
          </p>

          <h3>Cookie Details</h3>
          <ul>
            <li>
              <strong>Name:</strong> _ga
              <br />
              <strong>Purpose:</strong> Records a particular ID used to come up
              with data about website usage by the user
              <br />
              <strong>Provider:</strong> doitforme.eu
              <br />
              <strong>Service:</strong> Google Analytics
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> 1 year 1 month 4 days
            </li>
            <li>
              <strong>Name:</strong> _ga_#
              <br />
              <strong>Purpose:</strong> Used to distinguish individual users by
              means of designation of a randomly generated number as client
              identifier
              <br />
              <strong>Provider:</strong> .doitforme.eu
              <br />
              <strong>Service:</strong> Google Analytics
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> 1 year 1 month 4 days
            </li>
            <li>
              <strong>Name:</strong> _pk_id#
              <br />
              <strong>Purpose:</strong> Enables collection of statistics on user
              visit to the website anonymously
              <br />
              <strong>Provider:</strong> app.doitforme.eu
              <br />
              <strong>Service:</strong> Piwik Analytics Platform
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> 1 year 27 days
            </li>
            <li>
              <strong>Name:</strong> _gid
              <br />
              <strong>Purpose:</strong> Keeps an entry of unique ID used for
              statistical data on website usage by visitors
              <br />
              <strong>Provider:</strong> doitforme.eu
              <br />
              <strong>Service:</strong> Google Analytics
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> 23 hours 59 minutes
            </li>
            <li>
              <strong>Name:</strong> 676663723
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> www.google.com
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> United States
              <br />
              <strong>Type:</strong> pixel_tracker
              <br />
              <strong>Expires in:</strong> session
            </li>
            <li>
              <strong>Name:</strong> _gat
              <br />
              <strong>Purpose:</strong> Used to monitor number of Google
              Analytics server requests when using Google Tag Manager
              <br />
              <strong>Provider:</strong> doitforme.eu
              <br />
              <strong>Service:</strong> Google Analytics
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> less than 1 minute
            </li>
            <h3>Advertising cookies:</h3>
            <p>
              These cookies are used to make advertising messages more relevant
              to you. They perform functions like preventing the same ad from
              continuously reappearing, ensuring that ads are properly displayed
              for advertisers, and in some cases selecting advertisements that
              are based on your interests.
            </p>
            <li>
              <strong>Name:</strong> IDE
              <br />
              <strong>Purpose:</strong> Used to measure the conversion rate of
              ads presented to the user
              <br />
              <strong>Provider:</strong> .doubleclick.net
              <br />
              <strong>Service:</strong> DoubleClick
              <br />
              <strong>Country:</strong> United States
              <br />
              <strong>Type:</strong> server_cookie
              <br />
              <strong>Expires in:</strong> 1 year 11 months 29 days
            </li>
            <li>
              <strong>Name:</strong> _fbp
              <br />
              <strong>Purpose:</strong> Facebook tracking pixel used for
              personalized advertising
              <br />
              <strong>Provider:</strong> .doitforme.eu
              <br />
              <strong>Service:</strong> Facebook
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> 2 months 29 days
            </li>
            <li>
              <strong>Name:</strong> test_cookie
              <br />
              <strong>Purpose:</strong> Checks if the user's browser supports
              cookies
              <br />
              <strong>Provider:</strong> .doubleclick.net
              <br />
              <strong>Service:</strong> DoubleClick
              <br />
              <strong>Country:</strong> United States
              <br />
              <strong>Type:</strong> server_cookie
              <br />
              <strong>Expires in:</strong> 15 minutes
            </li>
            <li>
              <strong>Name:</strong> _gcl_au
              <br />
              <strong>Purpose:</strong> Used by Google AdSense for advertisement
              efficiency testing
              <br />
              <strong>Provider:</strong> doitforme.eu
              <br />
              <strong>Service:</strong> Google AdSense
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> 2 months 29 days
            </li>
            <h3>Unclassified Cookies</h3>
            <p>
              These are cookies that have not yet been categorized. We are in
              the process of classifying these cookies with the help of their
              providers.
            </p>
            <li>
              <strong>Name:</strong> AUTH_SESSION_ID_LEGACY
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> auth.doitforme.support
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> session
            </li>
            <li>
              <strong>Name:</strong> i18nextLng
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> app.doitforme.eu
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> html_local_storage
              <br />
              <strong>Expires in:</strong> persistent
            </li>
            <li>
              <strong>Name:</strong> CachedGigs
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> app.doitforme.eu
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> html_local_storage
              <br />
              <strong>Expires in:</strong> persistent
            </li>
            <li>
              <strong>Name:</strong> persistantState
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> app.doitforme.eu
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> html_local_storage
              <br />
              <strong>Expires in:</strong> persistent
            </li>
            <li>
              <strong>Name:</strong> lastExternalReferrerTime
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> app.doitforme.eu
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> html_local_storage
              <br />
              <strong>Expires in:</strong> persistent
            </li>
            <li>
              <strong>Name:</strong> AUTH_SESSION_ID
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> auth.doitforme.support
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> http_cookie
              <br />
              <strong>Expires in:</strong> session
            </li>
            <li>
              <strong>Name:</strong> lastExternalReferrer
              <br />
              <strong>Purpose:</strong> -
              <br />
              <strong>Provider:</strong> app.doitforme.eu
              <br />
              <strong>Service:</strong> -
              <br />
              <strong>Country:</strong> Greece
              <br />
              <strong>Type:</strong> html_local_storage
              <br />
              <strong>Expires in:</strong> persistent
            </li>
          </ul>
          <div id="control-cookies-browser">
            <h3>How Can I Control Cookies on My Browser?</h3>
            <p>
              As the means by which you can refuse cookies through your web
              browser controls vary from browser to browser, you should visit
              your browser's help menu for more information. The following is
              information about how to manage cookies on the most popular
              browsers:
            </p>
            <ul>
              <li>
                <strong>Chrome</strong>
              </li>
              <li>
                <strong>Internet Explorer</strong>
              </li>
              <li>
                <strong>Firefox</strong>
              </li>
              <li>
                <strong>Safari</strong>
              </li>
              <li>
                <strong>Edge</strong>
              </li>
              <li>
                <strong>Opera</strong>
              </li>
            </ul>
            <p>
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit:
            </p>
            <ul>
              <li>
                <strong>Digital Advertising Alliance</strong>
              </li>
              <li>
                <strong>Digital Advertising Alliance of Canada</strong>
              </li>
              <li>
                <strong>
                  European Interactive Digital Advertising Alliance
                </strong>
              </li>
            </ul>
          </div>

          <div id="other-tracking-technologies">
            <h3>What About Other Tracking Technologies, Like Web Beacons?</h3>
            <p>
              Cookies are not the only way to recognize or track visitors to a
              website. We may use other, similar technologies from time to time,
              like web beacons (sometimes called "tracking pixels" or "clear
              gifs"). These are tiny graphics files that contain a unique
              identifier that enables us to recognize when someone has visited
              our Website or opened an email including them. This allows us, for
              example, to monitor the traffic patterns of users from one page
              within a website to another, to deliver or communicate with
              cookies, to understand whether you have come to the website from
              an online advertisement displayed on a third-party website, to
              improve site performance, and to measure the success of email
              marketing campaigns. In many instances, these technologies are
              reliant on cookies to function properly, and so declining cookies
              will impair their functioning.
            </p>
          </div>

          <div id="flash-cookies">
            <h3>Do You Use Flash Cookies or Local Shared Objects?</h3>
            <p>
              Websites may also use so-called "Flash Cookies" (also known as
              Local Shared Objects or "LSOS") to, among other things, collect
              and store information about your use of our services, fraud
              prevention, and for other site operations.
            </p>
            <p>
              If you do not want Flash Cookies stored on your computer, you can
              adjust the settings of your Flash player to block Flash Cookies
              storage using the tools contained in the Website Storage Settings
              Panel. You can also control Flash Cookies by going to the Global
              Storage Settings Panel and following the instructions (which may
              include instructions that explain, for example, how to delete
              existing Flash Cookies (referred to as "information" on the
              Macromedia site), how to prevent Flash LSOS from being placed on
              your computer without your being asked, and (for Flash Player 8
              and later) how to block Flash Cookies that are not being delivered
              by the operator of the page you are on at the time).
            </p>
            <p>
              Please note that setting the Flash Player to restrict or limit
              acceptance of Flash Cookies may reduce or impede the functionality
              of some Flash applications, including, potentially, Flash
              applications used in connection with our services or online
              content.
            </p>
          </div>
          <div id="targeted-advertising">
            <h3>Do You Serve Targeted Advertising?</h3>
            <p>
              Third parties may serve cookies on your computer or mobile device
              to serve advertising through our Website. These companies may use
              information about your visits to this and other websites in order
              to provide relevant advertisements about goods and services that
              you may be interested in. They may also employ technology that is
              used to measure the effectiveness of advertisements. This can be
              accomplished by using cookies or web beacons to collect
              information about your visits to this and other sites to provide
              relevant advertisements about goods and services of potential
              interest to you. The information collected through this process
              does not enable us or them to identify your name, contact details,
              or other personal details unless you choose to provide these.
            </p>
          </div>

          <div id="cookie-policy-update">
            <h3>How Often Will You Update This Cookie Policy?</h3>
            <p>
              We may update this Cookie Policy from time to time to reflect, for
              example, changes to the cookies we use or for other operational,
              legal, or regulatory reasons. Please revisit this Cookie Policy
              regularly to stay informed about our use of cookies and related
              technologies.
            </p>
            <p>
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </p>
          </div>
          <div id="further-information">
            <h3>Where Can I Get Further Information?</h3>
            <p>
              If you have any questions about our use of cookies or other
              technologies, please email us at
              <a href="mailto:support@doitforme.eu">support@doitforme.eu</a> or
              by post to:
            </p>
            <p>
              DO IT FOR ME P.C
              <br />
              MIKRASIATON 75
              <br />
              VOLOS, MAGNESIA 38333
              <br />
              Greece
            </p>
          </div>
        </div>
        <div id="google-limited-use-requirments">
         <h2>15. GOOGLE'S LIMITED USE REQUIREMENTS </h2>
          <p>
           Doitforme's apps use and transfer to any other app of information received from Google Accounts will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes\" target="_blank\"> <span>Google API Services User Data Policy</span></a>
          , including the Limited Use requirements.
          </p>
        </div>
      </LegalRight>
    </Legal>
  );
}
