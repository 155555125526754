import { PageAlwaysVisible } from "monica-alexandria";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Legal,
  LegalDate,
  LegalLeft,
  LegalLogo,
  LegalRight,
  LegalTitle,
} from "./Legal.css";

export default function TERMS() {
  const { t } = useTranslation();

  return (
    <Legal>
      <LegalLeft>
        <LegalTitle>
          <LegalLogo src="https://genius1071.friktoriaservers.net/doitforme/logo/line_logo_dark.svg" />
          <h1>TERMS OF SERVICE</h1>
        </LegalTitle>
        <div id="table-of-contents">
          <ul>
            <li>
              <a href="#our-services">1. OUR SERVICES</a>
            </li>
            <li>
              <a href="#intellectual-property-rights">
                2. INTELLECTUAL PROPERTY RIGHTS
              </a>
            </li>
            <li>
              <a href="#user-representations">3. USER REPRESENTATIONS</a>
            </li>
            <li>
              <a href="#user-registration">4. USER REGISTRATION</a>
            </li>
            <li>
              <a href="#products">5. PRODUCTS</a>
            </li>
            <li>
              <a href="#purchases-and-payment">6. PURCHASES AND PAYMENT</a>
            </li>
            <li>
              <a href="#refunds-policy">7. REFUNDS POLICY</a>
            </li>
            <li>
              <a href="#prohibited-activities">8. PROHIBITED ACTIVITIES</a>
            </li>
            <li>
              <a href="#user-generated-contributions">
                9. USER GENERATED CONTRIBUTIONS
              </a>
            </li>
            <li>
              <a href="#contribution-licence">10. CONTRIBUTION LICENSE</a>
            </li>
            <li>
              <a href="#social-media">11. SOCIAL MEDIA & THIRD-PARTY ACCOUNT INTEGRATION</a>
            </li>
            <li>
              <a href="#third-party-websites-and-content">
                12. THIRD-PARTY WEBSITES AND CONTENT
              </a>
            </li>
            <li>
              <a href="#services-management">13. SERVICES MANAGEMENT</a>
            </li>
            <li>
              <a href="#privacy-policy">14. PRIVACY POLICY</a>
            </li>
            <li>
              <a href="#copyright-infringements">15. COPYRIGHT INFRINGEMENTS</a>
            </li>
            <li>
              <a href="#term-and-termination">16. TERM AND TERMINATION</a>
            </li>
            <li>
              <a href="#modifications-and-interruptions">
                17. MODIFICATIONS AND INTERRUPTIONS
              </a>
            </li>
            <li>
              <a href="#governing-law">18. GOVERNING LAW</a>
            </li>
            <li>
              <a href="#dispute-resolution">19. DISPUTE RESOLUTION</a>
            </li>
            <li>
              <a href="#corrections">20. CORRECTIONS</a>
            </li>
            <li>
              <a href="#disclaimer">21. DISCLAIMER</a>
            </li>
            <li>
              <a href="#limitations-of-liability">
                22. LIMITATIONS OF LIABILITY
              </a>
            </li>
            <li>
              <a href="#indemnification">23. INDEMNIFICATION</a>
            </li>
            <li>
              <a href="#user-data">24. USER DATA</a>
            </li>
            <li>
              <a href="#electronic-communications-transactions-and-signatures">
                25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </a>
            </li>
            <li>
              <a href="#miscellaneous">26. MISCELLANEOUS</a>
            </li>
            <li>
              <a href="#information-and-data-of-paid-users">
                27. INFORMATION AND DATA OF PAID USERS
              </a>
            </li>
            <li>
              <a href="#information-and-data-of-visitors">
                28. INFORMATION AND DATA OF VISITORS
              </a>
            </li>
            <li>
              <a href="#parameters-of-security-and-secrecy">
                29. PARAMETERS OF SECURITY AND SECRECY
              </a>
            </li>
            <li>
              <a href="#electronic-dispute-resolution">
                30. ELECTRONIC DISPUTE RESOLUTION
              </a>
            </li>
            <li>
              <a href="#code-of-conduct-for-e-commerce">
                31. CODE OF CONDUCT FOR E-COMMERCE
              </a>
            </li>
            <li>
              <a href="#contact-us">32. CONTACT US</a>
            </li>
            <li>
              <a href="#main-platforms-descriptions">
                33. MAIN PLATFORMS DESCRIPTIONS
              </a>
            </li>
            <li>
              <a href="#professional-service-providers">
                34. PROFESSIONAL SERVICE PROVIDERS
              </a>
            </li>
            <li>
              <a href="#special-terms-consumers">
                35. SPECIAL TERMS FOR CONSUMERS
              </a>
            </li>
          </ul>
        </div>
      </LegalLeft>
      <LegalRight>
        <LegalDate>
          <h6>Last updated: March 20th, 2025</h6>
        </LegalDate>
        <h1>AGREEMENT TO OUR LEGAL TERMS</h1>
        <p>
          We are DO IT FOR ME P.C ('Company', 'we', 'us', or 'our'), a company
          registered in Greece at MIKRASIATON 75, VOLOS, MAGNESIA 38333. Our VAT
          number is EL801134782.
        </p>

        <h2>Providers info</h2>
        <ul>
          <li>Greek General Commercial Registry’s number: 149893844000</li>
          <li>Name: DO IT FOR ME PC</li>
          <li>V.A.T.: 801134782</li>
          <li>
            Headquarters:
            <ul>
              <li>County: Magnesia</li>
              <li>Municipality: Volos</li>
              <li>Town: Volos</li>
              <li>Street: Mikrasiaton</li>
              <li>Number: 75</li>
              <li>Postal Code: 38333</li>
              <li>
                Email:
                <a href="mailto:support@doitforme.eu"> support@doitforme.eu</a>
              </li>
            </ul>
          </li>
        </ul>

        <p>
          We operate the website
          <a href="https://doitforme.eu"> doitforme.eu</a> (the 'Site'), as well
          as any other related products and services that refer or link to these
          legal terms (the 'Legal Terms') (collectively, the 'Services').
        </p>
        <p>
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ('you'), and DO IT
          FOR ME P.C, concerning your access to and use of the Services. You
          agree that by accessing the Services, you have read, understood, and
          agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE
          WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
          USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p>
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. We reserve the right, in our sole discretion, to
          make changes or modifications to these Legal Terms from time to time.
          We will alert you about any changes by updating the "Last updated'
          date of these Legal Terms, and you waive any right to receive specific
          notice of each such change. It is your responsibility to periodically
          review these Legal Terms to stay informed of updates. You will be
          subject to, and will be deemed to have been made aware of and to have
          accepted, the changes in any revised Legal Terms by your continued use
          of the Services after the date such revised Legal Terms are posted.
        </p>
        <p>
          All users who are minors in the jurisdiction in which they reside
          (generally under the age of 18) must have the permission of, and be
          directly supervised by, their parent or guardian to use the Services.
          If you are a minor, you must have your parent or guardian read and
          agree to these Legal Terms prior to you using the Services. We
          recommend that you print a copy of these Legal Terms for your records.
        </p>
        <p>
          For the sake of brevity, the provider above, will may be referred to
          as «Company».
        </p>
        <p>
          These terms apply to all service contracts concluded through this
          website (www.doitforme.eu) as well as to all cases where, under Greek
          legislation, there is a provision of services as a distance contract.
        </p>

        <ul>
          <li>
            These terms may be modified without prior notice to consumers. In
            any case, the modified terms will not apply retroactively.
          </li>
          <li>
            For the purposes of these terms, professions, traders and legal
            entities are also considered as consumers, insofar they fall within
            the concept of con-sumer, under National legislation, unless
            otherwise specified.
          </li>
          <li>
            Where Professional Sellers are referred to in these terms, this will
            apply to persons mentioned in the above section 34.
          </li>
        </ul>

        <div id="our-services">
          <h2>1. Our Services</h2>
          <p>
            The information provided when using the Services is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Services from other locations do so
            on their own initiative and are solely responsible for compliance
            with local laws, if and to the extent local laws are applicable.
          </p>
          <p>
            The Services are not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use the Services. You may not use the Services in a way that would
            violate the Gramm-Leach-Bliley Act (GLBA).
          </p>
        </div>
        <div id="intellectual-property-rights">
          <h2>2. Intellectual Property Rights</h2>

          <h3>Our Intellectual Property</h3>
          <p>
            We are the owner or the licensee of all intellectual property rights
            in our Services, including all source code, databases,
            functionality, software, website designs, audio, video, text,
            photographs, and graphics in the Services (collectively, the
            'Content'), as well as the trademarks, service marks, and logos
            contained therein (the "Marks").
          </p>
          <p>
            Our Content and Marks are protected by copyright and trademark laws
            (and various other intellectual property rights and unfair
            competition laws) and treaties in the United States and around the
            world.
          </p>
          <p>
            The Content and Marks are provided in or through the Services 'AS
            IS' for your personal, non-commercial use or internal business
            purpose only.
          </p>

          <h3>Your Use of Our Services</h3>
          <p>
            Subject to your compliance with these Legal Terms, including the
            'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
            non-transferable, revocable licence to:
          </p>
          <ul>
            <li>access the Services; and</li>
            <li>
              download or print a copy of any portion of the Content to which
              you have properly gained access,
            </li>
          </ul>
          <p>
            solely for your personal, non-commercial use or internal business
            purpose.
          </p>
          <p>
            Except as set out in this section or elsewhere in our Legal Terms,
            no part of the Services and no Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly
            displayed, encoded, translated, transmitted, distributed, sold,
            licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.
          </p>
          <p>
            If you wish to make any use of the Services, Content, or Marks other
            than as set out in this section or elsewhere in our Legal Terms,
            please address your request to:{" "}
            <a href="mailto:support@doitforme.eu">support@doitforme.eu</a>.
          </p>
          <p>
            If we ever grant you the permission to post, reproduce, or publicly
            display any part of our Services or Content, you must identify us as
            the owners or licensors of the Services, Content, or Marks and
            ensure that any copyright or proprietary notice appears or is
            visible on posting, reproducing, or displaying our Content.
          </p>
          <p>
            We reserve all rights not expressly granted to you in and to the
            Services, Content, and Marks.
          </p>
          <p>
            Any br/each of these Intellectual Property Rights will constitute a
            material br/each of our Legal Terms and your right to use our
            Services will terminate immediately.
          </p>
          <p>Your submissions and contributions</p>
          <p>
            Please review this section and the 'PROHIBITED ACTIVITIES' section
            carefully prior to using our Services to understand the (a) rights
            you give us and (b) obligations you have when you post or upload any
            content through the Services.
          </p>
          <p>
            Submissions: By directly sending us any question, comment,
            suggestion, idea, feedback, or other information about the Services
            (Submissions"), you agree to assign to us all intellectual property
            rights in such Submission. You agree that we shall own this
            Submission and be entitled to its unrestricted use and dissemination
            for any lawful purpose, commercial or otherwise, without
            acknowledgment or compensation to you.
          </p>
          <p>
            Contributions: The Services may invite you to chat, contribute to,
            or participate in blogs, message boards, online forums, and other
            functionality during which you may create, submit, post, display,
            transmit, publish, distribute, or br/oadcast content and materials
            to us or through the Services, including but not limited to text,
            writings, video, audio, photographs, music, graphics, comments,
            reviews, rating suggestions, personal information, or other material
            (Contributions"). Any Submission that is publicly posted shall also
            be treated as a Contribution.
          </p>
          <p>
            You understand that Contributions may be viewable by other users of
            the Services and possibly through third-party websites.
          </p>
          <p>
            When you post Contributions, you grant us a licence (including use
            of your name, trademarks, and logos): By posting any Contributions,
            you grant us an unrestricted, unlimited, irrevocable, perpetual,
            non-exclusive, transferable, royalty-free, fully-paid, worldwide
            right, and licence to: use, copy, reproduce, distribute, sell,
            resell, publish, br/oadcast, retitle, store, publicly perform,
            publicly display, reformat, translate, excerpt (in whole or in
            part), and exploit your Contributions (including, without
            limitation, your image, name, and voice) for any purpose,
            commercial, advertising, or otherwise, to prepare derivative works
            of, or incorporate into other works, your Contributions, and to
            sublicence the licences granted in this section. Our use and
            distribution may occur in any media formats and through any media
            channels.
          </p>
          <p>
            This licence includes our use of your name, company name, and
            franchise name, as applicable, and any of the trademarks, service
            marks, trade names, logos, and personal and commercial images you
            provide.
          </p>
          <p>
            You are responsible for what you post or upload: By sending us
            Submissions and/or posting Contributions through any part of the
            Services or making Contributions accessible through the Services by
            linking your account through the Services to any of your social
            networking accounts, you:
          </p>
          <ul>
            <li>
              confirm that you have read and agree with our 'PROHIBITED
              ACTIVITIES' and will not post, send, publish, upload, or transmit
              through the Services any Submission nor post any Contribution that
              is illegal, harassing, hateful, harmful, defamatory, obscene,
              bullying, abusive, discriminatory, threatening to any person or
              group, sexually explicit, false, inaccurate, deceitful, or
              misleading;
            </li>
            <li>
              to the extent permissible by applicable law, waive any and all
              moral rights to any such Submission and/or Contribution;
            </li>
            <li>
              warrant that any such Submission and/or Contributions are original
              to you or that you have the necessary rights and licences to
              submit such Submissions and/or Contributions and that you have
              full authority to grant us the above-mentioned rights in relation
              to your Submissions and/or Contributions; and
            </li>
            <li>
              warrant and represent that your Submissions and/or Contributions
              do not constitute confidential information.
            </li>
          </ul>
          <p>
            You are solely responsible for your Submissions and/or Contributions
            and you expressly agree to reimburse us for any and all losses that
            we may suffer because of your br/each of (a) this section, (b) any
            third party's intellectual property rights, or (c) applicable law.
          </p>
          <p>
            We may remove or edit your Content: Although we have no obligation
            to monitor any Contributions, we shall have the right to remove or
            edit any Contributions at any time without notice if in our
            reasonable opinion we consider such Contributions harmful or in
            br/each of these Legal Terms. If we remove or edit any such
            Contributions, we may also suspend or disable your account and
            report you to the authorities.
          </p>
          <p>Copyright infringement</p>
          <p>
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Services
            infringes upon any copyright you own or control, please immediately
            refer to the 'COPYRIGHT INFRINGEMENTS' section below.
          </p>
          <p>Doitforme does not monitor all user-generated content but reserves the right to remove or modify content that violates these terms.</p>
        </div>
        <div id="user-representations">
          <h2>3. User Representations</h2>
          <p>By using the Services, you represent and warrant that:</p>
          <ol>
            <li>
              All registration information you submit will be true, accurate,
              current, and complete;
            </li>
            <li>
              You will maintain the accuracy of such information and promptly
              update such registration information as necessary;
            </li>
            <li>
              You have the legal capacity and you agree to comply with these
              Legal Terms;
            </li>
            <li>
              You are not a minor in the jurisdiction in which you reside, or if
              a minor, you have received parental permission to use the
              Services;
            </li>
            <li>
              You will not access the Services through automated or non-human
              means, whether through a bot, script or otherwise;
            </li>
            <li>
              You will not use the Services for any illegal or unauthorised
              purpose; and
            </li>
            <li>
              Your use of the Services will not violate any applicable law or
              regulation.
            </li>
          </ol>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof).
          </p>
          <p>We reserve the right to request additional verification of your identity at any time.</p>
        </div>
        <div id="user-registration">
          <h2>4. User Registration</h2>
          <p>
            You may be required to register to use the Services. You agree to
            keep your password confidential and will be responsible for all use
            of your account and password. We reserve the right to remove,
            reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
          <p>We reserve the right to suspend or terminate any account that we believe has been compromised, used fraudulently, or violates these Terms</p>
        </div>
        <div id="products">
          <h2>5. Products</h2>
          <p>Prices for all plans are subject to change.</p>
          <p>
            Before the Plan Purchase, the details of what the plan offers are
            displayed.
          </p>
        </div>
        <div id="purchases-and-payment">
          <h2>6. Purchases and Payment</h2>
          <p>We accept the following forms of payment:</p>
          <ul>
            <li>Visa</li>
            <li>Mastercard</li>
            <li>American Express</li>
            <li>Discover</li>
          </ul>
          <p>
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Services. You
            further agree to promptly update account and payment information,
            including email address, payment method, and payment card expiration
            date, so that we can complete your transactions and contact you as
            needed. Sales tax will be added to the price of purchases as deemed
            required by us. We may change prices at any time. All payments shall
            be in Euros.
          </p>
          <p>
            You agree to pay all charges at the prices then in effect for your
            purchases and any applicable shipping fees, and you authorise us to
            charge your chosen payment provider for any such amounts upon
            placing your order. If your order is subject to recurring charges,
            then you consent to our charging your payment method on a recurring
            basis without requiring your prior approval for each recurring
            charge, until such time as you cancel the applicable order. We
            reserve the right to correct any errors or mistakes in pricing, even
            if we have already requested or received payment.
          </p>
          <p>
            We reserve the right to refuse any order placed through the
            Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole judgement, appear to be placed by
            dealers, resellers, or distributors.
          </p>
          <p>
            All financial transactions on{" "}
            <a href="https://app.doitforme.eu">app.doitforme.eu</a>,{" "}
            <a href="https://adsnap.doitforme.eu">adsnap.doitforme.eu</a>,{" "}
            <a href="https://questad.doitforme.eu">questad.doitforme.eu</a>,{" "}
            <a href="https://av.doitforme.eu">av.doitforme.eu</a>,{" "}
            <a href="https://agency.doitforme.eu">agency.doitforme.eu</a> for
            service fees are processed through Stripe, a third-party payment
            processing service. By utilizing our services, you authorize Stripe
            to process all payments, store financial information, and manage
            transactions as per their terms and policies. We are not responsible
            for the direct handling of your financial information and defer all
            payment disputes, refunds, and processing queries to Stripe's
            established procedures. Please refer to{" "}
            <a href="https://stripe.com/terms">Stripe's Terms of Service</a> for
            detailed information on their payment processing policies.
          </p>
        </div>
        <div id="refunds-policy">
          <h2>7. Refunds Policy</h2>
          <p>
            Our platform offers a 14-day refund policy from the date of purchase
            for any of our subscription plans. If you are not satisfied with the
            service provided, you may request a full refund within this period.
            To initiate a refund, please contact our support team at{" "}
            <a href="mailto:support@doitforme.eu">support@doitforme.eu</a> with
            your account information and purchase details. Refunds will be
            processed in accordance with the payment method used at the time of
            purchase. Please note that after the 14-day period, we cannot offer
            refunds. We reserve the right to refuse a refund request if we
            detect any abuse of our service.
          </p>
        </div>
        <div id="prohibited-activities">
          <h2>8. Prohibited Activities</h2>
          <p>
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavours except those
            that are specifically endorsed or approved by us.
          </p>
          <p>As a user of the Services, you agree not to:</p>
          <ul>
            <li>
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>
            <li>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user
              passwords.
            </li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Services and/or the Content contained therein.
            </li>
            <li>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Services.
            </li>
            <li>
              Use any information obtained from the Services in order to harass,
              abuse, or harm another person.
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </li>
            <li>
              Use the Services in a manner inconsistent with any applicable laws
              or regulations.
            </li>
            <li>
              Engage in unauthorized framing of or linking to the Services.
            </li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party's uninterrupted use and
              enjoyment of the Services or modifies, impairs, disrupts, alters,
              or interferes with the use, features, functions, operation, or
              maintenance of the Services.
            </li>
            <li>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </li>
            <li>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </li>
            <li>
              Attempt to impersonate another user or person or use the username
              of another user.
            </li>
            <li>
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats ('gifs"), 1x1 pixels, web bugs,
              cookies, or other similar devices (sometimes referred to as
              'spyware' or 'passive collection mechanisms' or 'pcms").
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </li>
            <li>
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access to the Services, or any portion of the
              Services.
            </li>
            <li>
              Copy or adapt the Services' software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other code.
            </li>
            <li>
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              in any way making up a part of the Services.
            </li>
            <li>
              Except as may be the result of standard search engine or Internet
              br/owser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              use or launch any unauthorized script or other software.
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Services.
            </li>
            <li>
              Make any unauthorized use of the Services, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email, or creating
              user accounts by automated means or under false pretenses.
            </li>
            <li>
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
            </li>
            <li>Sell or otherwise transfer your profile.</li>
            <li>
              Transfer or Sell the visitors information provided by the form
              submissions to others.
            </li>
          </ul>
        </div>
        <div id="user-generated-contributions">
          <h2>9.User Generated Contributions</h2>
          <p>
            The Services may invite you to chat, contribute to, or participate
            in blogs, message boards, online forums, and other functionality,
            and may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or br/oadcast
            content and materials to us or on the Services, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, 'Contributions'). Contributions may be viewable by
            other users of the Services and through third-party websites. As
            such, any Contributions you transmit may be treated as
            non-confidential and non-proprietary. When you create or make
            available any Contributions, you thereby represent and warrant that:
          </p>
          <ul>
            <li>
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </li>
            <li>
              You are the creator and owner of or have the necessary licences,
              rights, consents, releases, and permissions to use and to
              authorise us, the Services, and other users of the Services to use
              your Contributions in any manner contemplated by the Services and
              these Legal Terms.
            </li>
            <li>
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these
              Legal Terms.
            </li>
            <li>
              Your Contributions are not false, inaccurate, or misleading.
            </li>
            <li>
              Your Contributions are not unsolicited or unauthorised
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of solicitation.
            </li>
            <li>
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libellous, slanderous, or otherwise
              objectionable (as determined by us).
            </li>
            <li>
              Your Contributions do not ridicule, mock, disparage, intimidate,
              or abuse anyone.
            </li>
            <li>
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people.
            </li>
            <li>
              Your Contributions do not violate any applicable law, regulation,
              or rule.
            </li>
            <li>
              Your Contributions do not violate the privacy or publicity rights
              of any third party.
            </li>
            <li>
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of minors.
            </li>
            <li>
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap.
            </li>
            <li>
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Legal Terms, or any
              applicable law or regulation.
            </li>
          </ul>
          <p>
            Any use of the Services in violation of the foregoing violates these
            Legal Terms and may result in, among other things, termination or
            suspension of your rights to use the Services.
          </p>
          <p>We do not actively monitor all contributions but reserve the right to remove or report any content that violates these terms.</p>
        </div>
        <div id="contribution-licence">
          <h2>10. Contribution Licence</h2>
          <p>By submitting, posting, or making any content, materials, or contributions (“Contributions”) available on or through the Services, including but not limited to text, writings, videos, audio files, photographs, graphics, comments, or any other material, you grant DO IT FOR ME P.C. an unrestricted, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide license to:</p>
          <ul>
            <li> Host, store, archive, and cache such Contributions;</li>
            <li>Use, copy, reproduce, modify, distribute, display, publicly perform, publish, reformat, excerpt, or transmit such Contributions in part or in whole;</li>
            <li>Prepare derivative works of your Contributions or incorporate them into other materials;</li>
            <li>Use your name, trademarks, service marks, trade names, and company logos associated with your Contributions;</li>
            <li>Grant sublicenses to third parties to use the Contributions as permitted by this section.</li>
          </ul>
          <p>The above rights apply to all media, formats, and distribution methods now known or later developed, including but not limited to websites, mobile applications, social media, email marketing, advertisements, and other digital or printed formats.</p>
          <h3>Ownership & Responsibility</h3>
          <p>You retain full ownership of all your Contributions and any associated intellectual property rights.</p>
          <p>You confirm and warrant that you have the legal right to grant this license and that your Contributions do not infringe any third-party rights.</p>
          <p>You acknowledge that Contributions may be publicly visible and that DO IT FOR ME P.C. is not responsible for how others may use them.</p>
          <p>You waive any moral rights or claims related to modifications, edits, or use of your Contributions as outlined in this agreement.</p>
          <h3>Content Removal</h3>
          <p>We reserve the right, at our sole discretion, to edit, remove, or restrict access to any Contributions at any time, without notice, if we believe they violate these terms, applicable laws, or third-party rights.</p>
          <p>DO IT FOR ME P.C. assumes no liability for any user-generated content and disclaims responsibility for any legal claims arising from such Contributions.</p>
        </div>
        <div id="social-media">
          <h2>11. Social Media & Third-Party Account Integration</h2>
          <p>
          As part of our Services, you may link your account with third-party platforms, including but not limited to Facebook, Instagram, and Google (“Third-Party Accounts”). By linking a Third-Party Account, you grant DO IT FOR ME P.C. permission to:
          </p>
          <ul>
            <li>Access and retrieve data from your Third-Party Account, including profile details, advertisements, product catalogs, business insights, and engagement metrics.</li>
            <li> Store and display certain data from your Third-Party Account on our platform, such as your business profile, operating hours, product catalog, or advertisements.</li>
            <li> Use retrieved data to enhance and improve the Services, such as automating ad reporting, creating marketing profiles, or facilitating lead generation.</li>
          </ul>
          <h3>User Responsibilities & Data Permissions</h3>
          <ul>
            <li>You confirm that you have the necessary rights and authorizations to connect your Third-Party Account and allow us access.</li>
            <li>You acknowledge that certain data, including ads, catalog information, and insights, may be stored on DO IT FOR ME P.C.'s servers for service functionality.</li>
            <li>You can disconnect your Third-Party Account at any time, but previously stored data may continue to be used as part of the Services unless explicitly requested for deletion.</li>
          </ul>
          <h3>Automatic Data Collection & Storage</h3>
          <p>By connecting your Facebook account to every DO IT FOR ME P.C's Platforms and sub domains (doitforme.eu), you acknowledge and consent that we may collect and store:</p>
          <ul>
            <li>Advertising performance data (campaign insights, engagement metrics, conversions).</li>
            <li>Product catalogs and related data for eCommerce businesses.</li>
            <li>Business account metadata (Page details, settings, analytics).</li>
            <li>Lead form data submitted by users via Facebook Ads.</li>
          </ul>
          <p>DO IT FOR ME P.C. follows Meta’s data handling policies and ensures GDPR compliance by allowing users to request data deletion upon disconnection.
          </p>
          <h3>Termination of Third-Party Access</h3>
          <ul>
            <li>If Meta, Google, or another third-party provider restricts access to your account, some or all of your linked content may no longer be available through our Services.</li>
            <li> You may request deletion of stored data by contacting us at support@doitforme.eu.</li>
            <li>DO IT FOR ME P.C. is not liable for the loss of data or functionality caused by a third-party provider disabling access.</li>
          </ul>
          <h3>User Privacy & Data Responsibility</h3>
          <p>Your relationship with third-party service providers (such as Facebook or Instagram) is governed solely by your agreements with those platforms. We do not control or guarantee how these providers handle your data, nor do we assume responsibility for their privacy practices.</p>
          <p>By using our Services and linking a Third-Party Account, you agree to these terms and grant us permission to store and process your data as described above.</p>
        </div>
        <div id="third-party-websites-and-content">
          <h2>12. Third-Party Websites and Content</h2>
          <p>
            The Services may contain (or you may be sent via the Site) links to
            other websites ('Third-Party Websites') as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ('Third-Party
            Content'). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Services or any
            Third-Party Content posted on, available through, or installed from
            the Services, including the content, accuracy, offensiveness,
            opinions, reliability, privacy practices, or other policies of or
            contained in the Third-Party Websites or the Third-Party Content.
            Inclusion of, linking to, or permitting the use or installation of
            any Third-Party Websites or any Third-Party Content does not imply
            approval or endorsement thereof by us.
          </p>
          <p>
            If you decide to leave the Services and access the Third-Party
            Websites or to use or install any Third-Party Content, you do so at
            your own risk, and you should be aware these Legal Terms no longer
            govern. You should review the applicable terms and policies,
            including privacy and data gathering practices, of any website to
            which you navigate from the Services or relating to any applications
            you use or install from the Services. Any purchases you make through
            Third-Party Websites will be through other websites and from other
            companies, and we take no responsibility whatsoever in relation to
            such purchases which are exclusively between you and the applicable
            third party. You agree and acknowledge that we do not endorse the
            products or services offered on Third-Party Websites and you shall
            hold us blameless from any harm caused by your purchase of such
            products or services. Additionally, you shall hold us blameless from
            any losses sustained by you or harm caused to you relating to or
            resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>
        </div>
        <div id="services-management">
          <h2>13. Services Management</h2>
          <p>
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </p>
        </div>
        <div id="privacy-policy">
          <h2>14. Privacy Policy</h2>
          <p>
            We care about data privacy and security. Please review our Privacy
            Policy:
            <a
              href="https://launchpad.doitforme.eu/en/data-protection-policy"
              target="_blank"
            >
              https://app.doitforme.eu/en/privacy-policy
            </a>
            . By using the Services, you agree to be bound by our Privacy
            Policy, which is incorporated into these Legal Terms. Please be
            advised the Services are hosted in Greece. If you access the
            Services from any other region of the world with laws or other
            requirements governing personal data collection, use, or disclosure
            that differ from applicable laws in Greece, then through your
            continued use of the Services, you are transferring your data to
            Greece, and you expressly consent to have your data transferred to
            and processed in Greece.
          </p>
        </div>

        <div id="copyright-infringements">
          <h2>15. Copyright infringements</h2>
          <p>
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Services
            infringes upon any copyright you own or control, please immediately
            notify us using the contact information provided below (a
            'Notification'). A copy of your Notification will be sent to the
            person who posted or stored the material addressed in the
            Notification. Please be advised that pursuant to applicable law you
            may be held liable for damages if you make material
            misrepresentations in a Notification. Thus, if you are not sure that
            material located on or linked to by the Services infringes your
            copyright, you should consider first contacting an attorney.
          </p>
        </div>

        <div id="term-and-termination">
          <h2>16. Term and Termination</h2>
          <p>
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BR/EACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </p>
          <p>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
        </div>
        <div id="modifications-and-interruptions">
          <h2>17. Modifications and Interruptions</h2>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We also reserve the right to modify or
            discontinue all or part of the Services without notice at any time.
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Services.
          </p>
          <p>
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </p>
        </div>
        <div id="governing-law">
          <h2>18. Governing Law</h2>
          <p>
            These Legal Terms are governed by and interpreted following the laws
            of Greece, and the use of the United Nations Convention of Contracts
            for the International Sales of Goods is expressly excluded. If your
            habitual residence is in the EU, and you are a consumer, you
            additionally possess the protection provided to you by obligatory
            provisions of the law in your country of residence. DO IT FOR ME PC
            and yourself both agree to submit to the non-exclusive jurisdiction
            of the courts of Volos, which means that you may make a claim to
            defend your consumer protection rights in regards to these Legal
            Terms in Greece, or in the EU country in which you reside.
          </p>
        </div>

        <div id="dispute-resolution">
          <h2>19. Dispute Resolution</h2>
          <p>If you have a dispute with DO IT FOR ME P.C., we encourage you to first contact us directly at support@doitforme.eu to attempt an informal resolution.</p>
          <p>For consumers residing in the European Union (EU), the European Commission provides an Online Dispute Resolution (ODR) platform, which you can access at: <a target="_blank" href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></p>
          <p>If a dispute cannot be resolved informally, it may be referred to the relevant dispute resolution body through the ODR platform. However, we are not obligated to participate in dispute resolution via the ODR platform, and we reserve the right to require disputes to be resolved in the courts of Volos, Greece as stated in our Governing Law section.</p>
          <p>For non-EU users, any disputes must be resolved through mediation first, and if unsuccessful, legal action must be taken in the courts of Volos, Greece.</p>
        </div>

        <div id="corrections">
          <h2>20. Corrections</h2>
          <p>The information provided on the Services may occasionally contain typographical errors, inaccuracies, or omissions, including but not limited to descriptions, pricing, availability, and other details.</p>
          <p>We reserve the right to:</p>
          <ul>
            <li>Correct any errors, inaccuracies, or omissions at any time without prior notice.</li>
            <li>Modify or update information on the Services as needed.</li>
            <li>Change pricing and availability without obligation to honor incorrect prices due to errors.</li>
          </ul>
          <p>If a pricing error occurs on a product or service you have purchased, we reserve the right to:</p>
          <ul>
            <li>Cancel your order and issue a full refund, OR</li>
            <li>Contact you with the option to proceed with the purchase at the corrected price.</li>
          </ul>
          <p>We do not guarantee that all errors, inaccuracies, or omissions will be corrected immediately.</p>
        </div>

        <div id="disclaimer">
          <h2>21. Disclaimer</h2>
          <p>THE SERVICES ARE PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS. BY USING THE SERVICES, YOU ACKNOWLEDGE AND AGREE THAT:</p>
          <ul>
            <li>NO GUARANTEE OF SERVICE AVAILABILITY: We do not guarantee that the Services will always be uninterrupted, error-free, or available at all times. Service disruptions may occur due to server issues, technical failures, maintenance, cyberattacks, or other unforeseen circumstances.</li>
            <li> THIRD-PARTY SERVER PROVIDERS: The hosting and storage of data for the Services are managed by third-party providers, and we do not own or control the physical servers. Any service downtime, data loss, or security breach caused by the hosting provider is beyond our control and not our liability.</li>
            <li>LIMITATION OF WARRANTIES: TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO:</li>
            <ul>
              <li> THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
              <li>ANY WARRANTIES RELATED TO THE ACCURACY, COMPLETENESS, OR RELIABILITY OF CONTENT OR DATA ON THE SERVICES.</li>
              <li>ANY WARRANTIES THAT THE SERVICES WILL BE FREE OF MALWARE, VIRUSES, OR OTHER HARMFUL COMPONENTS.</li>
            </ul>
          </ul>
          <p>LIMITATION OF LIABILITY: UNDER NO CIRCUMSTANCES SHALL WE BE RESPONSIBLE FOR ANY DAMAGES OR LOSSES ARISING FROM:</p>
          <ol>
            <li>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT OR DATA.</li>
            <li>PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICES.</li>
            <li>UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR YOUR DATA, INCLUDING HACKING INCIDENTS OR DATA BREACHES.</li>
            <li>SERVICE DOWNTIME, DELAYS, OR INTERRUPTIONS CAUSED BY OUR THIRD-PARTY HOSTING PROVIDERS.</li>
            <li>MALWARE, BUGS, VIRUSES, TROJAN HORSES, OR OTHER HARMFUL TECHNOLOGIES TRANSMITTED THROUGH THE SERVICES BY THIRD PARTIES.</li>
            <li>LOSSES OR DAMAGES RESULTING FROM YOUR USE OF ANY CONTENT, SERVICES, OR PRODUCTS LINKED TO OUR PLATFORM.</li>
          </ol>
          <h3>Third-Party Services & Advertisers:</h3>
          <ul>
            <li>We do not control or endorse any third-party services, advertisements, websites, or mobile applications linked to our platform.</li>
            <li>Any transactions you conduct with third-party providers (such as payment processors, advertisers, or external eCommerce stores) are at your own risk.</li>
            <li>We are not responsible for any disputes, product defects, service failures, or financial losses related to these third-party interactions.</li>
          </ul>
          <h3>Data Storage & Security</h3>
          <ul>
              <li>We take reasonable data protection measures, but we cannot guarantee absolute security against cyber threats, server failures, or data breaches.</li>
              <li>If a security breach occurs due to our third-party hosting provider, we do not assume liability for any resulting data loss or exposure.</li>
          </ul>
          <br/><br/>
          <p>USE AT YOUR OWN RISK: YOU AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. WE DO NOT WARRANT OR GUARANTEE THE SAFETY, ACCURACY, OR RELIABILITY OF THE SERVICES AND YOU ACCEPT FULL RESPONSIBILITY FOR ANY RISK ASSOCIATED WITH YOUR USE.</p>
          <p>BY USING THE SERVICES, YOU EXPRESSLY AGREE TO THESE TERMS AND UNDERSTAND THAT WE ARE NOT LIABLE FOR ISSUES BEYOND OUR CONTROL, INCLUDING THIRD-PARTY FAILURES OR TECHNICAL DISRUPTIONS.</p>
        </div>

        <div id="limitations-of-liability">
          <h2>22. Limitations of Liability</h2>
          <p>To the maximum extent permitted by applicable law, we, including our directors, employees, or agents, shall not be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including but not limited to lost profits, lost revenue, loss of data, or other damages arising from your use of the services, even if we have been advised of the possibility of such damages.</p>
          <p>Notwithstanding anything to the contrary herein, our total liability to you for any claim arising from or relating to the use of the services shall not exceed the amount paid by you for the services during the one (1) month period prior to the claim.</p>
          <p>This limitation of liability shall not apply to liability resulting from:</p>
          <ul>
            <li>Our gross negligence, fraud, or intentional misconduct;</li>
            <li>Our breach of mandatory statutory obligations;</li>
            <li>Liability that cannot be limited or excluded under applicable law, including but not limited to consumer protection or data protection laws.</li>
          </ul>
          <p>If applicable laws in your jurisdiction do not allow certain exclusions or limitations of liability, some or all of the above limitations may not apply to you, and you may have additional rights under law.</p>
        </div>

        <div id="indemnification">
          <h2>23. Indemnification</h2>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys' fees
            and expenses, made by any third party due to or arising out of: (1)
            your Contributions; (2) use of the Services; (3) br/each of these
            Legal Terms; (4) any br/each of your representations and warranties
            set forth in these Legal Terms; (5) your violation of the rights of
            a third party, including but not limited to intellectual property
            rights; or (6) any overt harmful act toward any other user of the
            Services with whom you connected via the Services. Notwithstanding
            the foregoing, we reserve the right, at your expense, to assume the
            exclusive defence and control of any matter for which you are
            required to indemnify us, and you agree to cooperate, at your
            expense, with our defence of such claims. We will use reasonable
            efforts to notify you of any such claim, action, or proceeding which
            is subject to this indemnification upon becoming aware of it.
          </p>
        </div>
        <div id="user-data">
          <h2>24. User Data</h2>
          <p>
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </p>
          <p>Data Processing Agreement (DPA) By using our Services, you acknowledge that DO IT FOR ME P.C. may process personal data on your behalf. We act as both a Data Controller and a Data Processor, depending on the service you use.</p>
          <ul>
            <li>As a Data Controller, we determine how and why user data is processed.</li>
            <li>As a Data Processor, we process data from Facebook, Google, and advertising platforms on your behalf.</li>
            <li>We ensure that any third-party services we use (such as hosting providers, payment processors, and analytics tools) are GDPR-compliant and have their own Data Processing Agreements.</li>
            <li> Users may request a separate DPA agreement for their business by contacting support@doitforme.eu.
            Lead Data Storage & Responsibility</li>
            <li>Leads generated through our platform are stored for a maximum of 24 months, after which they are automatically deleted unless users export or request retention.</li>
            <li>Users who collect lead data from ads or form submissions are solely responsible for handling customer information in compliance with GDPR and other data protection laws.</li>
            <li>If you receive personal data via our services, you must:</li>
            <ul>
              <li>Only use it for legitimate business purposes.</li>
              <li>Protect it against unauthorized access.</li>
              <li> Ensure you comply with GDPR when storing or transferring leads.</li>
            </ul>
          </ul>
        </div>

        <div id="electronic-communications-transactions-and-signatures">
          <h2>25. Electronic Communications, Transactions, and Signatures</h2>
          <p>
          By visiting our Services, sending emails, or filling out online forms, you acknowledge that you are engaging in electronic communication. You consent to receive communications from us electronically, including agreements, notifications, disclosures, and other important information via email or through the Services. These electronic communications will be considered legally valid and satisfy any requirements that such communications be in writing.
          </p>
          <p>By using the Services, you also agree to:</p>
          <ul>
            <li>Electronic signatures for contracts, transactions, and agreements.</li>
            <li>Electronic processing of orders, records, and notices without requiring a physical copy.</li>
            <li>Electronic delivery and storage of policies, legal agreements, and transaction records.</li>
          </ul>
          <p>You waive any legal rights or requirements that mandate:</p>
          <ul>
            <li>Physical (non-electronic) signatures on contracts or agreements.</li>
            <li>Paper-based documentation or record-keeping instead of electronic records.</li>
            <li> Non-digital payment methods unless explicitly required by applicable law.</li>
          </ul>
          <p>If you have any concerns regarding electronic communications or transactions, please contact us at support@doitforme.eu.</p>
        </div>

        <div id="miscellaneous">
          <h2>26. Miscellaneous</h2>
          <p>
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defences you may
            have based on the electronic form of these Legal Terms and the lack
            of signing by the parties hereto to execute these Legal Terms.
          </p>
        </div>

        <div id="information-and-data-of-paid-users">
          <h2>27. Information and Data of Paid Users</h2>
          <ul>
            <li>When a user starts creating a Quest, they provide their explicit consent for the automatic creation of a free profile page on our platform.</li>
            <li>This profile will be publicly accessible on our Marketplace and the internet, displaying the business name, services/products, location, and contact details provided during Quest creation.</li>
            <li>If a user disconnects their Facebook account from our platform, we will continue to store and publicly display the previously collected data until the user manually deletes it or requests a full profile deletion from their account settings.</li>
            <li>Users can edit, update, or delete their profile at any time from their account settings.</li>
            <li>If a user requests deletion, all associated data will be permanently removed from our systems within 7 days. However, previously indexed content on search engines may take longer to disappear.</li>
          </ul>
        </div>

        <div id="information-and-data-of-visitors">
          <h2>28. Information and Data of Visitors</h2>
          <ul>
            <li>Visitors who submit a request form on our platform consent to their inquiry being shared with registered and verified service providers in the relevant category.</li>
            <li>Their contact information (e.g., email, phone number) will only be visible to eligible service providers who match their request criteria.</li>
            <li>Visitors may request the deletion of their submitted data at any time by contacting support at support@doitforme.eu or adjusting their privacy settings.</li>
          </ul>
        </div>
        <div id="parameters-of-security-and-secrecy">
          <h2>29. Parameters of Security and Secrecy</h2>
          <ul>
            <li>
              Use an SSL security certificate to encrypt data between our client
              and our central server.
            </li>
            <li>Encryption of the codes in the database.</li>
          </ul>
        </div>

        <div id="electronic-dispute-resolution">
          <h2>30. Electronic Dispute Resolution</h2>
          <p>
            The company supports the alternative dispute resolution through the
            EU extra-judicial Platform:
          </p>
          <ul>
            <li>
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EL"
                target="_blank"
              >
                https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EL
              </a>
            </li>
          </ul>
        </div>

        <div id="code-of-conduct-for-e-commerce">
          <h2>31. Code of Conduct for E-Commerce</h2>
          <ul>
            <li>
              <a href="https://bit.ly/2Umg3x2" target="_blank">
                https://bit.ly/2Umg3x2
              </a>
            </li>
          </ul>
        </div>
        <div id="contact-us">
          <h2>32. Contact Us</h2>
          <p>
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </p>
          <p>
            DO IT FOR ME P.C
            <br />
            MIKRASIATON 75
            <br />
            VOLOS, MAGNESIA 38333
            <br />
            Greece
            <br />
            <a href="mailto:support@doitforme.eu">support@doitforme.eu</a>
          </p>
        </div>
        <div id="main-platforms-descriptions">
          <h2>33. Main Platforms Descriptions</h2>
          <h3>portal.doitforme.eu</h3>
          <ul>
            <li>Acts as a centralized dashboard displaying all Doitforme ecosystem applications.</li>
            <li>Provides access to all connected services, including Adsnap, Questad, Taskmap, Sites, Marketplace, and Payments.</li>
            <li>Users can modify personal account settings, such as theme colors, language, and location.</li>
            <li>Offers privacy and policy options, allowing users to change their password, disable their account, or permanently delete their data (including Google and Meta data) within 7 days.</li>
          </ul>
          <h3>adsnap.doitforme.eu</h3>
          <ul>
            <li>Allows users to connect their Meta (Facebook & Instagram) and Google accounts.</li>
            <li>Grants permissions on Meta (ads_management, business_management, ads_read, etc.) and Google (userinfo.profile, adwords) to generate ad performance reports.</li>
            <li>Enables users to upload their own photos and information to reports.</li>
            <li>Provides options to download reports as PDFs or share them via a public link.</li>
          </ul>
          <h3>questad.doitforme.eu</h3>
          <ul>
            <li>Enables users to run Meta ads for Facebook & Instagram business pages.</li>
            <li>Connects to Facebook and obtains permissions for campaign creation, insights, lead retrieval, and catalog management.</li>
            <li>Users can monitor ad insights, edit campaigns, and update ad content through an interactive dashboard.</li>
            <li>Stores Meta account data with user consent to create a profile within the Doitforme network for additional exposure.</li>
            <li>Sends lead notifications via email when users receive new inquiries.</li>
          </ul>
          <h3>taskmap.doitforme.eu</h3>
          <ul>
            <li>Visitors can submit requests for services they need, which are displayed on an interactive map.</li>
            <li>Doitforme users can claim available service tasks, receiving contact details for direct communication.</li>
            <li>Visitor information may be publicly accessible to users claiming tasks, provided the visitor consents during form submission.</li>
            <li>Service details and user interactions happen outside of the platform.</li>
          </ul>
          <h3>sites.doitforme.eu</h3>
          <ul>
            <li>Automatically generates mini-websites for users based on Meta data collected through Questad campaigns.</li>
            <li>Offers three customizable templates: Service, Local Store, and eShop.</li>
            <li>Users can manually edit their mini-site, modifying business details, descriptions, working hours, product catalogs, and more.</li>
            <li>Website content is publicly available and optimized for search engines.</li>
          </ul>
          <h3>marketplace.doitforme.eu</h3>
          <ul>
            <li>Acts as a search platform where visitors can find businesses and services.</li>
            <li>Displays profiles from sites.doitforme.eu along with their business offerings and contact details.</li>
            <li>Users can search for businesses, services, and products, all of which are public.</li>
          </ul>
          <h3>payments.doitforme.eu</h3>
          <ul>
            <li>Manages financial transactions within the Doitforme ecosystem.</li>
            <li>Facilitates payments for advertising, subscriptions, and other services.</li>
            <li>Integrates with Stripe for secure payment processing, ensuring compliance with international financial regulations.</li>
          </ul>
        </div>

        <div id="professional-service-providers">
          <h2>34. Professional Service Providers</h2>
          <p>
            Special terms that apply only to professionals, traders, legal
            entities and individuals who have been registered as «Professional
            Sellers» on app.doitforme.eu and/or questad.doitforme.eu:
          </p>
          <h3>a. Services</h3>
          <p>
            The company’s services consist of the promotion of professionals,
            traders, legal entities, and individuals who want to advertise their
            services or their products, and are registered as "Professional
            sellers".
          </p>

          <h3>b. Conclusion Time of a Service Contract</h3>
          <p>From selecting the "Order With An Obligation To Pay" button.</p>

          <h3>c. Declarations</h3>
          <ul>
            <li>
              You will not post advertisements for services or sales that under
              the Greek legislation are unlawful, or require a license to
              practice, or that are manifestly contrary to the accepted
              principles of morality.
            </li>
            <li>
              Do not carry out acts that fall under the provision on unfair
              competition.
            </li>
            <li>
              Do not make derogatory, defamatory or negative statements for
              third parties or enterprises.
            </li>
            <li>
              Do not make false statements about the quality, price, etc.
              related to your services or products.
            </li>
            <li>
              The evaluation of your services and products from other registered
              users.
            </li>
            <li>
              Observe the provisions of the article 9 of the number 5338
              (Government Gazette B’ 40/17.01.2018) Ministerial Decision.
            </li>
            <li>
              If an extrajudicial statement or document from a competent
              authority is notified to us, we will disclose your personal data
              and/or your contact information to third parties in order to
              exercise their rights.
            </li>
            <li>
              You acknowledge that the Company is solely responsible for serious
              negligence or deception excluding liability for minor negligence.
            </li>
            <li>
              By your registration and the entry of your products/services or
              graphics etc. you do not violate the intellectual property of
              third parties.
            </li>
            <li>
              For personal data that you receive from the registered users of
              the website, you will observe the relevant national and European
              legislation on the protection of personal data.
            </li>
            <li>
              You are responsible for the accuracy of the information you
              provide for the issue of the relevant tax document.
            </li>
          </ul>

          <h3>d. Payment Methods</h3>
          <ul>
            <li>
              Credit/Debit Cards:
              <ul>
                <li>
                  By using cards on our website in order to make purchases, you
                  declare that you have the legal right to use them.
                </li>
                <li>
                  The company does not keep your card details, nor is it
                  responsible for the payment infrastructure of STRIPE.
                </li>
              </ul>
            </li>
          </ul>

          <h3>e. Fee</h3>
          <p>The fee as illustrated on this website does not include VAT.</p>

          <h3>f. Discounts/Gifts/Promotions</h3>
          <ul>
            <li>
              The company from time to time may launch various promotional
              actions such as discounts, gifts, etc. These promotions can also
              be performed on social media or through search sites.
            </li>
            <li>
              In any case, the only correct representation of the company’s
              prices is the one provided through this website.
            </li>
          </ul>

          <h3>g. Right of Withdrawal</h3>
          <ul>
            <li>
              You are entitled to withdraw from the service contract within 14
              days without giving any explanation.
            </li>
            <li>
              The withdrawal deadline expires in 14 days from the following day
              that the service contract was drawn up.
            </li>
            <li>
              In order to exercise the right of withdrawal, you must notify
              about your decision to withdraw from this contract with a clear
              statement addressed via email to the company.
            </li>
            <li>
              You can use the attached template of withdrawal form, without this
              being mandatory.
            </li>
            <li>
              If you use this feature, we will promptly transmit to you via
              email a confirmation of your withdrawal.
            </li>
            <li>
              In order to comply with the withdrawal period, it is obligatory to
              send your statement on the exercise of your right of withdrawal
              before the above withdrawal period expires.
            </li>
          </ul>

          <h3>h. Consequences of the Withdrawal</h3>
          <ul>
            <li>
              If you withdraw from this contract, we will return to you all the
              money received from you, without unjustified delay, and anyway
              within 14 days from the day that we will be informed about your
              decision to withdraw from this contract.
            </li>
            <li>
              We will execute the above refund using the same payment method you
              used for the initial transaction, unless you have explicitly
              agreed to something different and in any case, you will not be
              charged for such a refund.
            </li>
            <li>
              After your repayment, all entries you have made until the day of
              your repayment will be deleted. Only the necessary tax and
              accounting data will be retained in our records. Your personal
              data (except the above-mentioned) will be deleted the following
              day after your repayment.
            </li>
          </ul>

          <h3>i. Breach of Terms</h3>
          <ul>
            <li>
              If the above terms are not complied with in any way, the company
              will be entitled to: a.) remove any material that infringes any
              third party’s rights, and/or b.) delete your account without
              returning the service fee, and/or c.) delete specific entries that
              violate these terms.
            </li>
          </ul>
        </div>

        <div id="special-terms-consumers">
          <h2>35. Special Terms for Consumers</h2>

          <h3>a. Purpose of the Terms</h3>
          <ul>
            <li>
              This special section of the terms applies only to consumers who
              communicate through the website or acquaint themselves with the
              contact information of professionals etc. referred to in section
              34 of these terms.
            </li>
          </ul>

          <h3>b. Conclusion of Service and Sales Contract</h3>
          <ul>
            <li>
              The company does not provide services or sell products through
              this website except those expressly referred to in section 34
              paragraph a.
            </li>
            <li>
              The company is not responsible or in any way mediates for service
              or sales contracts that are concluded through the direct
              communication with the "Professional Sellers" and individuals in
              this section of these terms.
              <ul>
                <li>
                  For the purposes of this section, direct communication is also
                  the use of the website’s infrastructure for the exchange of
                  direct messages.
                </li>
              </ul>
            </li>
          </ul>

          <h3>c. Declarations</h3>
          <ul>
            <li>
              By using this website and obtaining information from it, you
              explicitly accept the following:
              <ul>
                <li>
                  You acknowledge that the company does not provide any services
                  or products other than those referred to in section 34
                  paragraph a.
                </li>
                <li>
                  You acknowledge that the company is not responsible for any
                  statement, act, omission, behavior, or any other harmful
                  action for which liability may be directly attributed to the
                  professional registered on the website etc.
                </li>
                <li>
                  By your registration and the entry of graphics etc. you do not
                  violate the intellectual property rights of third parties.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </LegalRight>
    </Legal>
  );
}
