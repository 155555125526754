import { AlertContext, Alert_, Builder_, Checkbox_, Focus_, Form, FormInput, H3, H5, Input_, P, QuestadComission, device } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Cover from '../../../assets/landingQuestad/HeaderCover.jpg'
import { create_user } from '../../../services/api/Requests'

const HeaderProfit = styled.div`
    padding: 2rem 2rem 2rem 4rem;
    position: relative;

    .FirstRow{
        grid-template-columns: 1fr 1fr;
    }

    .Budget{
        
        h5{
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: baseline;
            gap: 1rem;

            .Price{
                padding: 2.5rem 2rem 1.5rem 2rem;
                box-shadow: ${p => p.theme.in};
                background: ${p => p.theme.background};
                border-radius: var(--smallRadius);
            }
        }
    }

    @media ${device.lg} {
        padding: 2rem;

        .FirstRow{
            
            div:first-of-type{
                margin-bottom: 2rem;
            }
        }
    }

    @media ${device.md} {
        margin: 2rem;
    }

    @media ${device.sm} {
        padding: .5rem;
    }
`

const HeaderCover = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--normalRadius);
`

const HeaderOverlay = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--normalRadius);
    background: var(--vanta);
    opacity: .3;
`

const HeaderWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media ${device.lg} {
        grid-template-columns: 1fr;
        grid-gap: 4rem;
    }
`

const HeaderHero = styled.div`

    .Run{
        display: block;
        font-size: 7.5rem;
    }

    .Fb{
        display: block;
        font-size: 6rem;
    }

    .Insta{
        display: block;
        font-size: 6.6rem;
    }

    .Manage{
        display: block;
        font-size: 3.35rem;
    }

    .From{
        font-size: 4rem;
        font-family: 'FontLight';
    }

    .Price{
        color: var(--focus);
        font-size: 9rem;
    }

    .Month{
        font-size: 3.5rem;
        font-family: 'FontLight';
    }

    h5,
    h1{
        color: var(--white);
    }

    h5{
        font-family: "fontLight";
        margin-top: 2rem;
    }

    @media ${device.md} {
        h5,
        h1{
            padding: 1rem;
        }

        .Run{
            display: block;
            font-size: 5rem;
        }

        .Fb{
            font-size: 3rem;
        }

        .Manage{
            display: block;
            font-size: 2rem;
            letter-spacing: 1px;
        }

        .Price{
            color: var(--focus);
            font-size: 5rem;
        }

        .Month{
            font-size: 2rem;
            font-family: 'FontLight';
        }

        h5,
        h1{
            color: var(--white);
        }

        h5{
            font-size: 1.6rem;
            margin-top: 0;
        }
    }
`

const HeaderRight = styled.div`
    display: flex;
    justify-content: flex-end;
`

const HeaderForm = styled(Form)`
    max-width: 60rem;
    transform: scale(1.05);
    border-radius: 2.3rem;

    h3{
        text-align: center;
    }

    button{
        width: 100%;
        justify-content: center;
    }

    @media ${device.lg} {
        transform: scale(1);
        max-width: none;
        width: 100%;
    }
`

const HeaderSlider = styled.div`
    
    input {
        -webkit-appearance: none; /* Override default CSS styles */
        background: transparent; /* Make the slider background transparent */
        width: 100%;

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 1rem;
            background: ${p => p.theme.mid};
            border-radius: var(--smallRadius);
            box-shadow: ${p => p.theme.in};
        }

        &::-moz-range-track {
            width: 100%;
            height: 1rem;
            background: ${p => p.theme.mid};
            border-radius: var(--smallRadius);
            box-shadow: ${p => p.theme.in};
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none; 
            border: none;
            height: 2rem;
            width: 2rem; 
            border-radius: 50%;
            background: var(--main); 
            margin-top: -.5rem; 
            cursor: pointer;
        }

        &::-moz-range-thumb {
            -webkit-appearance: none; 
            border: none;
            height: 2rem;
            width: 2rem; 
            border-radius: 50%;
            background: var(--main); 
            margin-top: -.5rem; 
            cursor: pointer;
        }

        &::-webkit-slider-runnable-track{
            /* background: linear-gradient(to right, var(--main), var(--mainDark)); */
            width: 100%;
            height: 1rem;
            border-radius: var(--smallRadius);
        }

        &::-moz-range-progress{
            background: linear-gradient(to right, var(--main), var(--mainDark));
            width: 100%;
            height: 1rem;
            border-radius: var(--smallRadius);
        }
    }
`
const HeaderFormSuccess = styled(Form)`
    text-align: center;

    img{
        max-width: 25rem;
        width: 100%;
        margin-bottom: 2rem;
    }

    h3{
        margin-bottom: 0rem;
    }
`
export default function Header_Dailyprofit() {

    const {t} = useTranslation()
    const [fee, setFee] = useState(4.50);
    const [formInput,setFormInput] = useState({budget:50.00, name: '', surname:'', email:'', tel:'', terms:false});

    const { alerts, setAlerts } = useContext(AlertContext);
    const [alertSuccess, setAlertSuccess] = useState(false);
     

    const messagesInitialState = {
        name: {errors: []},
        surname: {errors: []},
        email: {
        errors: [],
        helpers: [],
        successes: [],
        },
        tel: {
            errors: [],
            helpers: [],
            successes: [],
        },

    }

    const [messages, setMessages] = useState(messagesInitialState);


    const validateTel = (telNumber) => {
        let helpers = [];
        let successes = [];
        setFormInput({...formInput, tel: telNumber});
        // Regular expression to match a valid telephone number format
        const telRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
        
        if (telNumber !== '') {
            if (!telRegex.test(telNumber)) {
                helpers.push("Please enter a valid phone number");
            } else {
                successes.push("Phone number is valid");
            }
        }

        setMessages(prevMessages => ({
            ...prevMessages,
            tel: {
                ...prevMessages.tel,
                helpers: helpers,
                successes: successes,
            }
        }));
    };


    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    const validateEmail = (email) => {
    let helpers = [];
    let successes = [];

    setFormInput({...formInput, email: email});

    // Regular expression to match a valid email format
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,50}$/;

    if (email !== '') {
        if (!emailRegex.test(email)) {
            helpers.push(t("Correct email format email@domain.com"));
        } else {
            successes.push(t("Email address is valid."));
        }
    }
    
    setMessages(prevMessages => ({
        ...prevMessages,
        email: {
            ...prevMessages.Email,
            helpers: helpers,
            successes: successes,
        }
    }));
    };

    const checkEmptyInputs = (key) =>{
    let erroremptyinput = t('This field should not be empty')
    setMessages(prevMessages => ({
        ...prevMessages,
        [key]: {
        ...prevMessages[key],
        errors: [erroremptyinput],
        }
    }));
    
    };

    const clearField = (field) => { 
    setFormInput(prevFormInput => ({
        ...prevFormInput,
        [field]: '',
    }))
    }


    //check inputs on submit
    const checkInputs = e =>{
      e.preventDefault();

        const cleanedFormInput = {
            ...formInput,
            name: formInput.name.replace(/\s+/g, '').toLowerCase(),
            surname: formInput.surname.replace(/\s+/g, '').toLowerCase(),
            email: formInput.email.replace(/\s+/g, '').toLowerCase(),
            tel: formInput.tel.replace(/\s+/g, ''),
        };
        setFormInput(cleanedFormInput);

      const isEmpty = Object.entries(cleanedFormInput).some(([key, value]) => value === '');
      if (isEmpty) {
          if (cleanedFormInput.name == "") checkEmptyInputs('name');
          if (cleanedFormInput.surname === '') checkEmptyInputs('surname');
          if (cleanedFormInput.email === '') checkEmptyInputs('email');
          if (cleanedFormInput.tel === '') checkEmptyInputs('tel');
      }
    
      const emailHelpers = messages?.email?.helpers || [];
      const telHelpers = messages?.tel?.helpers || [];
        
      if (emailHelpers?.length || telHelpers?.length ) {
            if (emailHelpers.length) {
                
                setMessages(prevMessages => ({
                    ...prevMessages,
                    email: {
                        ...prevMessages.email,
                        errors: emailHelpers,
                        helpers: []
                    }
                }));
                clearField('email');
             
            }
            if (telHelpers.length) {
                setMessages(prevMessages => ({
                    ...prevMessages,
                    tel: {
                        ...prevMessages.tel,
                        errors: telHelpers,
                        helpers: []
                    }
                }));
              clearField('tel');
            }
      
      } 
    if (!cleanedFormInput?.terms)  alertMessage(t('Please ensure that you have accepted the terms and conditions & privacy policy '), 'Error');


      if (!isEmpty && cleanedFormInput?.terms && (!emailHelpers?.length || !telHelpers?.length)) {
        setAlertSuccess(true);
        const source = 'Questad'
        const urlParams = new URLSearchParams(window.location.search);
        const tag_source = urlParams.get('source');
        create_user(cleanedFormInput?.email, source,'',cleanedFormInput, tag_source)


        .then(res => {
        })
        .then(err => {
        })
      }
    }

    useEffect(() => {
        let commission=13;
        // setFee({percentage: commission.toFixed(2), type: t("Comission fee"), fee: (commission*0.01*formInput?.budget).toFixed(2)})
        setFee(QuestadComission(formInput?.budget, t("Comission fee"), '13'))
    }, [formInput?.budget])
    

  return (
    <HeaderProfit>
        <Alert_ messages={alerts} duration={4000} />
        <HeaderCover src={Cover} />
        <HeaderOverlay></HeaderOverlay>
        <HeaderWrapper>
            <HeaderHero>
                <h1><span className='Run'>{t("Run ads on")}</span> <span className='Fb'>Facebook & Instagram</span><span className='Manage'>{t("with management cost from")}</span><span className='Price'>€ {fee.fee}</span><span className='Month'>{t("/month")}</span></h1>
                <H5>{t("Ideal for small businesses, freelancers, solopreneurs & business owners.")}</H5>
            </HeaderHero>
            <HeaderRight>
            {!alertSuccess ?
                 <HeaderForm>
                    <H3>{t("Learn More!")}<br/>{t("Get a call from our team")}</H3>
                    <Builder_ className="Budget" title={t("Set the budget you wish to spend on Ads")}>
                        <HeaderSlider>
                            <input
                                type="range"
                                min="50"
                                max="1000"
                                value={formInput?.budget}
                                onChange={(e)=> setFormInput({...formInput, budget:e.target.value})} 
                                step="1" 
                            />
                        </HeaderSlider>
                        <h5>
                            <span className='Price'>
                                € {formInput?.budget}.00 
                            </span>
                            {t("per month")}
                        </h5>
                    </Builder_>
                    <Builder_ split className="FirstRow" >
                        <Input_ 
                            placeholder={t("First name")} 
                            onChange = {(e)=> setFormInput({...formInput, name:e.target.value})}
                            errors = {messages?.name?.errors}
                            value = {formInput?.name}
                            required 
                        />
                        <Input_ 
                            placeholder={t("Last name")} 
                            onChange = {(e)=> setFormInput({...formInput, surname:e.target.value})}
                            errors = {messages?.surname?.errors}
                            value = {formInput?.surname}
                            required 
                        />
                    </Builder_>
                    <Builder_>
                        <Input_ 
                            placeholder={t("Email address")} 
                            onChange = {(e)=>validateEmail(e.target.value)}
                            helpers = {messages?.email?.helpers}
                            successes = {messages?.email?.successes}
                            errors = {messages?.email?.errors}
                            value = {formInput?.email}
                            required 
                        />
                    </Builder_>
                    <Builder_>
                        <Input_ 
                            placeholder={t("Phone number")} 
                            onChange = {(e)=>validateTel(e.target.value)}
                            helpers = {messages?.tel?.helpers}
                            value = {formInput?.tel}
                            successes = {messages?.tel?.successes}
                            errors = {messages?.tel?.errors}
                            required 

                        />
                    </Builder_>
                    <Builder_>
                        <Checkbox_ 
                            checked={formInput?.terms}
                            onClick={()=> setFormInput({...formInput, terms:!formInput.terms})}
                            text={
                                <p>{t("I have read and accept the")} <a>{t("Terms of Use")}</a> {t("& the")} <a>{t("Privacy Policy")}</a> {t("of DO IT FOR ME P.C.")}</p>
                            }
                        />
                    </Builder_>
                    <Focus_ size="large" text={t("Submit")} onClick = {checkInputs}/>
                 </HeaderForm>
                 : 
                 <HeaderFormSuccess>
                    
                    <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/global/Success.svg' />
                    <H3>{t("Form successfully submitted")}</H3>
                    <P>{t("We will contact you in the information you provided as soon as possible")}</P>
                    <h6>{t("Thank you for your trust")}</h6>
                    <h6>{t("The Doitforme team")}</h6>
                 </HeaderFormSuccess>
                }
            </HeaderRight>
        </HeaderWrapper>
    </HeaderProfit>
  )
}
